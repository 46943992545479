import React, { useState, useEffect } from "react"
import app from "../utils/base"
import CircleLoader from "react-spinners/CircleLoader"
import apiCall from "../Api"
import { classId } from "../App"

export const AuthContext = React.createContext()

const filterAccounts = (account) => {
  return account.class.id === classId
}

const AuthProvider = (props) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [accountChoose, setAccountChoose] = useState(false)
  const [studentsATC, setStudentsATC] = useState([])
  const [teachersATC, setTeachersATC] = useState([])
  const [adminsATC, setAdminsATC] = useState([])

  useEffect(() => {
    setStudentsATC([])
    setTeachersATC([])
    setAdminsATC([])
    app.auth().onAuthStateChanged((user) => {
      setLoading(true)
      // console.log("user", user)
      if (user) {
        const vars = {
          email: user.email,
          classId,
        }

        const GET_USER = `query($email: String, $classId: String) {
          users(
            where: {
              email: { equals: $email }
              OR: [
                {
                  student: {
                    some: { class: { id: { equals: $classId } } }
                  }
                }
                {
                  teacher: {
                    some: { class: { id: { equals: $classId } } }
                  }
                }
                {
                  admin: {
                    some: { class: { id: { equals: $classId } } }
                  }
                }
              ]
            }
            
          ) {
            id
            email
            token
            isVerified
            status
            student {
              id
              name
              rollNo
              contact
              subjects{
                id
                name
              }
              dateOfBirth
              batch {
                id
                name
                group {
                  id
                  name
                }
              }
              class{
                id
                name
              }
            }
            teacher {
              id
              name
              contact
              subjects {
                id
                name
                group{
                  id
                  name
                  
                }
              }
              class {
                id
                name
              }
              role
              dateOfBirth
              status
            }
            admin {
              id
              name
              class {
                id
                name
              }
            }
          }
        }`

        apiCall(GET_USER, vars).then((res) => {
          console.log("fetching user")
          if (res.errors) {
            console.log("Fetch user failed", res.errors)
            setLoading(false)
          } else {
            const studentAccounts = res.data.users[0].student.filter(
              filterAccounts
            )
            const teacherAccounts = res.data.users[0].teacher.filter(
              filterAccounts
            )
            const adminAccounts = res.data.users[0].admin.filter(filterAccounts)
            const totalAccountsLength =
              studentAccounts.length +
              teacherAccounts.length +
              adminAccounts.length
            if (totalAccountsLength > 1) {
              //redirect him/her to account choose
              setAccountChoose(true)
              setStudentsATC(studentAccounts)
              setTeachersATC(teacherAccounts)
              setAdminsATC(adminAccounts)
              setLoading(false)
            } else if (totalAccountsLength === 1) {
              if (studentAccounts.length === 1) {
                const user = {
                  type: "student",
                  ...studentAccounts[0],
                }
                setUser(user)
                setLoading(false)
              } else if (teacherAccounts.length === 1) {
                onChoose(teacherAccounts[0], "teacher")
              } else if (adminAccounts.length === 1) {
                const user = {
                  type: "admin",
                  ...adminAccounts[0],
                }
                setUser(user)
                setLoading(false)
              }
            } else {
              setUser(null)
              setLoading(false)
            }
          }
        })
      } else {
        setUser(null)
        setLoading(false)
      }
    })
  }, [])

  const onChoose = (account, accntType) => {
    let type = accntType
    if (type === "teacher") {
      const adminRoles = ["Proprietor", "Manager", "Consultant"]
      if (adminRoles.includes(account.role)) {
        type = "admin"
      }
    }
    const user = { type, ...account }
    setUser(user)
    setAccountChoose(false)
    setLoading(false)
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <CircleLoader
            // css={override}
            size={150}
            color={"#FFA500"}
            loading={loading}
          />
        </div>
      ) : (
          <>
            {accountChoose ? (
              <AccountChooseScreen
                studentsATC={studentsATC}
                teachersATC={teachersATC}
                adminsATC={adminsATC}
                choose={onChoose}
              />
            ) : (
                props.children
              )}
          </>
        )}
    </AuthContext.Provider>
  )
}

const AccountChooseScreen = (props) => {
  return (
    <div className="bg-gray-100 h-screen">
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm leading-5 text-blue-700">
              Please choose which account to proceed with to portal.
            </p>
          </div>
        </div>
      </div>
      {props.studentsATC.map((accnt) => (
        <AccountCard
          {...accnt}
          choose={() => props.choose(accnt, "student")}
          title={"Student"}
          key={accnt.id}
        />
      ))}

      {props.teachersATC.map((accnt) => (
        <AccountCard
          {...accnt}
          choose={() => props.choose(accnt, "teacher")}
          title={"Teacher"}
          key={accnt.id}
        />
      ))}

      {props.adminsATC.map((accnt) => (
        <AccountCard
          {...accnt}
          choose={() => props.choose(accnt, "admin")}
          title={"Admin"}
          key={accnt.id}
        />
      ))}
    </div>
  )
}

const AccountCard = (props) => {
  return (
    <ul  className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-9xl  py-4 px-4 sm:px-6 lg:px-8">
      <li className="col-span-1 bg-white rounded-lg shadow ">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-gray-900 p-4 text-3xl leading-5 font-medium truncate">
                {props.title}
              </h3>
            </div>
            <p className="mt-1 text-gray-500 p-4 text-xl leading-5 truncate">
              {props.name}
            </p>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <div className="-mt-px flex bg-indigo-600">
            <div className="w-0 flex-1 flex border-r border-gray-200">
              <button
                onClick={props.choose}
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
              >
                <span className="ml-3 text-lg text-white">Select</span>
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default AuthProvider
