import React from "react"
import PuffLoader from "react-spinners/PuffLoader"

const ButtonLoader =(props)=>{
    return(
        <div className="mr-5">
        <PuffLoader
          size={props.size}
          color={"#FFFFFF"}
          loading={props.loading}
        />
      </div>

    )
}
export default ButtonLoader