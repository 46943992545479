import React, { useContext, useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import { AuthContext } from "./AuthContext"
import app from "../utils/base"

const PrivateRoute = ({ children, allowed,...rest }) => {
  const { user } = useContext(AuthContext)

  if (user) {
    if(allowed){
      if(user.type===allowed){
        return <Route {...rest}>{children}</Route>
      } else {
        return <Redirect to="/" />
      }
    }
    return <Route {...rest}>{children}</Route>
  }

  return <Logout />
}

const Logout = () => {
  useEffect(() => {
    app
      .auth()
      .signOut()
      .then(() => {
        console.log("Sign out successful")
      })
  }, [])

  return <Redirect to="/" />
}

export default PrivateRoute
