import React, { useState } from "react"
import * as firebase from "firebase/app"
import { Redirect } from "react-router-dom"

const ForgotPassword = () => {
  const auth = firebase.auth()
  const [email, setEmail] = useState("")
  const [redirect, setRedirect] = useState(false)

  const proceed = () => {
    if (!email) {
      alert("Please provide email to proceed")
      return
    }
    auth.sendPasswordResetEmail(email).then(function () {
      // console.log("email sent")
      setRedirect(true)
      alert("If this  email  is registered with us  you would have received a email to set the password")
    }).catch(function (error) {
      console.log(error)
    });
  }

  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <div className="bg-gray-100 h-screen ">
      <div className="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto ">
          <div className="bg-white shadow sm:rounded-lg ">
            <div className="px-4 py-5 sm:p-6 ">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                provide your email
              </h3>
              <div className="mt-5 sm:flex sm:items-center">
                <div className="max-w-xs w-full">
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <input
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-input block w-full sm:text-sm sm:leading-5"
                      placeholder="you@example.com"
                    />
                  </div>
                </div>
                <span className="mt-3 inline-flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto">
                  <button
                    onClick={proceed}
                    type="button"
                    className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 sm:w-auto sm:text-sm sm:leading-5"
                  >
                    proceed
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
