import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyC7FD_16lohTJ7wrk4-fCl3Wy-xLZXJxno",
  authDomain: "virtual-class-9bf90.firebaseapp.com",
  databaseURL: "https://virtual-class-9bf90.firebaseio.com",
  projectId: "virtual-class-9bf90",
  storageBucket: "virtual-class-9bf90.appspot.com",
  messagingSenderId: "35261336817",
  appId: "1:35261336817:web:50e538b4d781bbc3e7ad50",
}

const app = firebase.initializeApp(firebaseConfig)

export default app

export const storage = firebase.storage()

export function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}
