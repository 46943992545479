import axios from "axios";
import { useQuery, queryCache, useMutation } from "react-query";

const axiosStellarGraphQL = axios.create({
  baseURL: "https://virtual.leadwithcode.com/graphql",
  //baseURL: "http://localhost:7007/graphql",
});

const apiCall = async (query, variables = {}) => {
  const response = await axiosStellarGraphQL.post("", { query, variables });
  return { data: response.data.data, errors: response.data.errors };

  // .then(result =>{
  //   console.log(
  //     "GraphQL result data",
  //     result.data.data,
  //     "GraphQL result error",
  //     result.data.errors
  //   )
  //   // result2 = {data: result.data.data, errors:result.data.errors}
  // }
  // )
};

export const useCustomQuery = (key, query, variables, options = {}) => {
  const fetcher = () => {
    return apiCall(query, variables);
  };

  const invalidateCache = () => {
    queryCache.invalidateQueries(key);
  };

  return [useQuery(key, fetcher, options), invalidateCache];
};

export const useCustomMutation = (configuration) => {
  const customMutation = ({ query, variables }) => {
    apiCall(query, variables);
  };
  return useMutation(customMutation, configuration);
};

export default apiCall;

export const GET_GROUPS = `
query($classId: StringNullableFilter){
  groups(where:{
    classId:$classId
    
  }orderBy:{
    order:asc
  }){
    id
    name
    batches{
      id
      name
      group{
        name
      }
    }
    subjects{
      id
      name
    }
  }
}
`;
export const GET_GROUPS_USER = `
query($classId: StringNullableFilter $userId:String){
  groups(where:{
    classId:$classId
    OR:{
      subjects:{some:{teachers:{some:{id:{equals:$userId}}}}}
    }
  }orderBy:{
    order:asc
  }){
    id
    name
    batches{
      id
      name
      group{
        name
      }
    }
    subjects{
      id
      name
    }
  }
}
`;

export const CREATE_GROUP = `
mutation($name:String!, $classId:String){
  createOneGroup(data:{
    name:$name,
    class:{
      connect:{
        id:$classId
      }
    }
  }){
    id
  }
}
`;

export const GET_BATCHES = `
query($groupId:StringNullableFilter){
  batches(where:{
    groupId:$groupId
  }orderBy:{
    name:asc
  }){
    id
    name
  }
}
`;

export const CREATE_BATCH = `
mutation($name: String!, $groupId:String){
    createOneBatch(
      data:{
        name:$name
        group:{
          connect:{
            id:$groupId
          }
        }
      }
    ){
      id
    }
  }
`;

export const DELETE_BATCH = `
mutation($batchId:String){
    deleteOneBatch(where:{
      id:$batchId
    }){
      id
    }
  }
`;

export const DELETE_GROUP = `
mutation($groupId:String){
    deleteOneGroup(where:{
      id:$groupId
    }){
      id
    }
  }
`;

export const UPDATE_BATCH_NAME = `
mutation($batchId:String, $name:String){
  updateOneBatch(where:{
    id:$batchId
  }data:{
    name:{set:$name}
  }){
    id
  }
}
`;

export const GET_SUBJECTS = `
query($groupId:StringNullableFilter){
  subjects(where:{
    groupId:$groupId
  }orderBy:{
    name:asc
  }){
    id
    name
  }
}
`;

export const CREATE_SUBJECT = `
mutation($name: String!, $groupId:String){
    createOneSubject(
      data:{
        name:$name
        group:{
          connect:{
            id:$groupId
          }
        }
      }
    ){
      id
    }
  }
`;

export const DELETE_SUBJECT = `
mutation($subjectId:String){
    deleteOneSubject(where:{
      id:$subjectId
    }){
      id
    }
  }
`;
export const DELETE_TEACHER = `
mutation($teacherId:String){
    deleteOneTeacher(where:{
      id:$teacherId
    }){
      id
    }
  }
`;

export const DELETE_STUDENT = `
mutation($studentId:String){
    deleteOneStudent(where:{
      id:$studentId
    }){
      id
    }
  }
`;

export const UPDATE_SUBJECT_NAME = `
mutation($subjectId:String, $name:String){
  updateOneSubject(where:{
    id:$subjectId
  }data:{
    name:{set:$name}
  }){
    id
  }
}
`;

export const ADD_STUDENT_WITH_USER = `
mutation(
  $name:String!
  $rollNo:String
  $contact:String
  $gName:String
  $gContact:String
  $rContact:String
  $currentCollage:String
  $per10th:String
  $per11th:String
  $per12th:String
  $perFY:String
  $perSY:String
  $perTY:String
  $birthday:String
  $address:String
  $classId:String
  $batchId:String
  $email:String!
  $subjects:[SubjectWhereUniqueInput!]
  $isNewStudent: Boolean
){
  upsertOneUser(where:{
    email:$email
  }
    update:{
    student:{create:{
    name:$name
    rollNo:$rollNo
    contact:$contact
    gaurdianName:$gName
    guardianContact:$gContact
    residentialContact:$rContact
    currentCollege:$currentCollage
    percentage10th:$per10th
    percentage11th:$per11th
    percentage12th:$per12th
    percentageFY:$perFY
    percentageSY:$perSY
    percentageTY:$perTY
    dateOfBirth:$birthday
    address:$address
    class:{connect:{id:$classId}}
    batch:{connect:{id:$batchId}}
    subjects:{connect:$subjects}
    }}}
    
    create:{
      email:$email
      isVerified:$isNewStudent
      student:{create:{
    name:$name
    rollNo:$rollNo
    contact:$contact
    gaurdianName:$gName
    guardianContact:$gContact
    residentialContact:$rContact
    currentCollege:$currentCollage
    percentage10th:$per10th
    percentage11th:$per11th
    percentage12th:$per12th
    percentageFY:$perFY
    percentageSY:$perSY
    percentageTY:$perTY
    dateOfBirth:$birthday
    address:$address
    class:{connect:{id:$classId}}
    batch:{connect:{id:$batchId}}
    subjects:{connect:$subjects}
    }}
      
    }
    
      ){
    id
  }
}
`;

export const UPDATE_STUDENT = `
mutation(
  $name:String!
  $rollNo:String
  $contact:String
  $gName:String
  $gContact:String
  $rContact:String
  $currentCollage:String
  $per10th:String
  $per11th:String
  $birthday:String
  $address:String
  $batchId:String
  $subjects:[SubjectWhereUniqueInput!]
  $studentId:String
){
  updateOneStudent(data:{
    name:{set:$name}
    rollNo:{set:$rollNo}
    contact:{set:$contact}
    gaurdianName:{set:$gName}
    guardianContact:{set:$gContact}
    residentialContact:{set:$rContact}
    currentCollege:{set:$currentCollage}
    percentage10th:{set:$per10th}
    percentage11th:{set:$per11th}
    dateOfBirth:{set:$birthday}
    address:{set:$address}
    batch:{connect:{id:$batchId}}
    subjects:{set:$subjects}
    
  },where:{id:$studentId}){
    id
  }
}
`;
export const GET_ALL_BATCHES = `
query($classId:StringNullableFilter){
  batches(where:{group:{classId:$classId}}orderBy:{
    name:asc
  }){
    id 
    name
    group{
      id
      name
    }
  }
}
`;

export const GET_ALL_SUBJECTS = `
query($batchId: String) {
  subjects(
    where: { group: { batches: { some: { id: { contains: $batchId } } } } }
    orderBy: { name: asc }
  ) {
    id
    name
  }
}
`;

export const CREATE_TEACHER_WITH_USER = `
mutation(
  $name: String!
  $contact: String
  $subjects: [SubjectWhereUniqueInput!]
  $role: String
  $dateOfBirth: String
  $status: String
  $classId: String
  $email: String!
  $isNewTeacher:Boolean
) {
  upsertOneUser(
    where: { email: $email }
    update: {
      teacher: {
        create: {
          name: $name
          contact: $contact
          subjects: { connect: $subjects }
          role: $role
          dateOfBirth: $dateOfBirth
          status: $status
          class: { connect: { id: $classId } }
        }
      }
    }

    create: {
      email: $email
      isVerified:$isNewTeacher
      teacher: {
        create: {
          name: $name
          contact: $contact
          subjects: { connect: $subjects }
          role: $role
          dateOfBirth: $dateOfBirth
          status: $status
          class: { connect: { id: $classId } }
        }
      }
    }
  ) {
    id
  }
}

`;

export const UPDATE_TEACHER = `
mutation($teacherId:String,$name:String, $contact:String,  $subjects:[SubjectWhereUniqueInput!], $role:String, $dateOfBirth:String, $status:String){
  updateOneTeacher(
    where:{
      id:$teacherId
    }
    data:{
    name:{set:$name}
    contact:{set:$contact}
    subjects:{
      set:$subjects
    }
    role:{set:$role}
    dateOfBirth:{set:$dateOfBirth}
    status:{set:$status}
  }){
    id
  }
}
`;
export const GET_ALL_STUDENTS = `
query($classId: StringFilter ) {
  students(where: { class: { id: $classId }  }, orderBy: { name: asc }) {
    id
    name
    contact
    currentCollege
    dateOfBirth
    gaurdianName
    guardianContact
    residentialContact
    id
    rollNo
    percentageFY
    percentageSY
    percentageTY
    percentage10th
    percentage11th
    percentage12th
    address
    subjects {
      id
      name
    }
    batch {
      id
      name
      group{
        name
      }
    }
    user {
      id
      email
    }
  }
}

`;

export const GET_SUBJECTS_ALL = `
query{
  subjects(orderBy:{
    name:asc}){
    id 
    name
    group{
      id
      name
    }
  }
}
`;

export const GET_ALL_TEACHERS = `
query($classId:StringFilter){
  teachers(where:{class:{id:$classId}}orderBy:{
    name:asc
  }){
    id 
    name
    contact
    role
    subjects{
      id
      name
      group{
        id
        name
      }
    }
    dateOfBirth
    user{
      email
    }
  }
}
`;

export const GET_STUDENTS_WITH_SUBJECT_GROUP = `
query($batchId:String $subjectId:String){
  students(where:{batch:{id:{contains:$batchId}},
    subjects:{some:{id:{contains:$subjectId}}}}){
    id 
    name  
    rollNo   
}
}
`;
export const GET_SUBJECTS_GROUPWISE = `
query($batchId:StringFilter){
  subjects(where:{group:{batches:{some:{id:$batchId}}}}orderBy:{
    name:asc
  }){
    id
    name
  }
}
`;

export const STUDENTS_DAYWISE_ATTENDANCE = `
mutation($day:DateTime! $subjectId:String
  $batchId:String,$attendes:[AttendeeCreateWithoutAttendanceInput!]){
    createOneAttendance(data:{
      day:$day
      subject:{
        connect:{id:$subjectId}
      }
      batch:{
        connect:{id:$batchId}
      }
      attendees:{
        create:$attendes
      }
    }){
      id
    }
  }
`;

export const GET_ATTENDANCE_SUBJECTWISE = `
query($batchId: String, $subjectId: String) {
  attendances(
    where: {
      batch: { id: { equals: $batchId } }
      subject: { id: { equals: $subjectId } }
    }
    orderBy:{
      day:desc
    }
    first:10
  ) {
    id
    day
    attendees {
      id
      status
      student {
        id
        name
      }
    }
  }
}
`;

export const GET_STUDENTS_ATTENDANCES = `
query($studentId: String) {
  attendees(where: { student: { id: { equals: $studentId } } }
  first:10) {
    id
    status
    attendance {
      id
      day
      subject {
        id
        name
      }
    }
  }
}
`;

export const CREATE_PERIOD = `
mutation($day:String!,$timetableId: String){
  createOnePeriod(data:{
		day: $day
    timetable:{connect:{id:$timetableId}}
  }){
    id
  }
}
`;

export const UPDATE_PERIOD_SUBJECT = `
mutation($periodId: String, $var:String){
  updateOnePeriod(where:{
    id:$periodId
  }data:{
    subject:{connect:{id:$var}}
  }){
    id
  }
}
`;

export const UPDATE_PERIOD_TEACHER = `
mutation($periodId: String, $var:String){
  updateOnePeriod(where:{
    id:$periodId
  }data:{
    teacher:{connect:{id:$var}}
  }){
    id
  }
}
`;

export const UPDATE_PERIOD_START_TIME = `
mutation($periodId: String, $var:String){
  updateOnePeriod(where:{
    id:$periodId
  }data:{
    startTime:{set:$var}
  }){
    id
  }
}
`;

export const UPDATE_PERIOD_END_TIME = `
mutation($periodId: String, $var:String){
  updateOnePeriod(where:{
    id:$periodId
  }data:{
    endTime:{set:$var}
  }){
    id
  }
}
`;

export const UPDATE_TIMETABLE_STATUS = `
mutation($timetableId:String, $status:Boolean){
  updateOneTimetable(where:{
    id:$timetableId
  }data:{
    status:{set:$status}
  }){
    id
    status
  }
  }
`;

export const GET_SUBJECTS_WITH_CHAPTERS = `
query($classId:String){
  subjects(where:{group:{class:{id:{equals:$classId}}}}){
    id
    name
    chapters{
      id
      title
      notes{
        id
        title
        url
      }
    }
    group{
      id
      name
    }
  }
}
`;

export const GET_SUBJECTS_WITH_CHAPTERS_FOR_USER = `
query($classId: String, $userId: String) {
  subjects(where: { group: { class: { id: { equals: $classId } } } OR:[
    {students:{some:{id:{equals:$userId}}}}
    {teachers:{some:{id:{equals:$userId}}}}
  ]}
  ) {
    id
    name
    chapters {
      id
      title
      notes {
        id
        title
        url
      }
    }
    group {
      id
      name
    }
  }
}
`;

export const ADD_SUBJECT_CHAPTER = `
mutation($title:String! $subjectId:String){
  createOneChapter(data:{
    title:$title
    subject:{connect:{id:$subjectId}}
  }){
    id
  }
}
`;

export const ADD_NOTE_CHAPTER = `
mutation($title:String!,$chapterId:String,$url:String!,$altText:String!,
	$fileName:String!,$type:String!,$classId:String
){
  createOneNote(data:{
    title:$title
    url:$url
    chapter:{connect:{id:$chapterId}}
    cloudDoc:{
      create:{
        name:$title
        fileName:$fileName
        url:$url
        type:$type
        altText:$altText
        Class:{connect:{id:$classId}}
      }
    }
  }){
    id
  }
}
`;

export const GET_LIVE_PERIODS_OF_TEACHER = `
query($selectedTeacher:StringNullableFilter, $status:BooleanFilter){
  periods(where:{
    teacherId:$selectedTeacher
    timetable:{
      status:$status
    }
  }){
    id
    day
    subject{
      id
      name
    }
    startTime
    endTime
  }
}
`;

export const DELETE_MANY_ATTENDEE_OF_A_ATTENDANCE = `
mutation($attendanceId:String){
  deleteManyAttendee(where:{attendance:{id:{equals:$attendanceId}}}){
    count
  }
}
`;

export const DELETE_ATTENDANCE = `
mutation($attendanceId:String){
  deleteOneAttendance(where:{id:$attendanceId}){
    id
  }
}`;

export const DELETE_ALL_TIMETABLE_PERIODS = `
mutation($timetableId: String!, $timetableId1:StringFilter){
  updateOneTimetable(where:{
    id:$timetableId
  }data:{
    periods:{deleteMany:{timetableId:$timetableId1}}
  }){
    id
    name
  }
}
`;

export const UPDATE_ATTENDANCE = `
mutation($day:DateTime! $subjectId:String
  $batchId:String,
  $attendanceId:String
  $attendes:[AttendeeCreateWithoutAttendanceInput!]){
    updateOneAttendance(data:{
      day:{set:$day}
      subject:{
        connect:{id:$subjectId}
      }
      batch:{
        connect:{id:$batchId}
      }
      attendees:{
        create:$attendes
      }
    }where:{id:$attendanceId}){
      id
    }
  }
  `;

export const DELETE_ONE_PERIOD = `
mutation($periodId:String){
  deleteOnePeriod(where:{
    id:$periodId
  }){
    id
  }
}

`;

export const DELETE_NOTE = `
mutation($noteId:String){
  deleteOneNote(where:{id:$noteId}){
    id
  }
}
`;

export const DELETE_NOTE_CLOUD_DOC = `
mutation($noteId:String){
  updateOneNote(where:{
    id:$noteId
  }data:{
    cloudDoc:{delete:true}
  }){
    id
  }
}
`;

export const UPDATE_NOTE = `
mutation($title:String!
  $noteId:String
$url:String!){
  updateOneNote(data:{
    title:{set:$title}
    url:{set:$url}
  }where:{id:$noteId}){
    id
  }
}
`;

export const UPDATE_CHAPTER = `
mutation($title:String! $chapterId:String){
  updateOneChapter(data:{
    title:{set:$title}
  }where:{id:$chapterId}){
    id
  }
}
`;
export const DELETE_MANY_NOTES = `
mutation($chapterId:String){
  deleteManyNote(where:{chapter:{id:{equals:$chapterId}}}){
    count
  }
}
`;
export const DELETE_CHAPTER = `
mutation($chapterId:String){
  deleteOneChapter(where:{id:$chapterId}){
    id
  }
}
`;

export const GET_BATCH_STUDENTS = `
query($batchId:String){
  students(where:{batch:{id:{equals:$batchId}}}){
    id
  }
  }
`;
export const GET_SUBJECT_TEACHERS_STUDENTS = `
query($subjectId:String){
  subject(where:{id:$subjectId}){
    id
    students{
      id
    }
    teachers{
    id
  }
  }
  }
`;
export const DELETE_MANY_ATTENDEEOF_A_ATTENDANCE = `
mutation($attendanceId:String){
  deleteManyAttendee(where:{attendance:{id:{equals:$attendanceId}}}){
    count
  }
}
`;

export const UPDATE_CLASS = `
mutation($name:String! $classId:String
  $website:String $address:String $contact:String){
   updateOneClass(data:{
     name:{set:$name}
     contact:{set:$contact}
     website:{set:$website}
     address:{set:$address}
   }where:{id:$classId}){
     id
   }
 }
`;

export const GET_CLASS = `
query($classId:String){
  class(where:{id:$classId}){
    id
    name
    contact
    website
    address
  }
}

`;

export const GET_CLASS_BROCHERS = `
query($classId:String){
  cloudDocs(where:{Class:{id:{equals:$classId}}
  type:{equals:"brocher-pdf"}}){
    id
    name
    url
    altText
  }
  
}

`;

export const CREATE_CLASS_BROCHER = `
mutation($classId:String $name:String!
  $fileName:String! $url:String! $type:String! $altText:String!){
   createOneCloudDoc(data:{
         name:$name
         fileName:$fileName
         url:$url
         type:$type
         altText:$altText
         Class:{
          connect:{
            id:$classId
          }
        }
   }){
     id
   }
 }
`;

export const DELETE_CLASS_BROCHER = `
mutation($id:String){
  deleteOneCloudDoc(where:{id:$id}){
    id
  }
}
`;

export const GET_STUDENT_ATTENDEE = `
query($id:String){
  student(where:{id:$id}){
    attendee{
      id
    }
  }
}
`;
export const GET_LAST_STUDENT_ROLLNO = `
query($id: String) {
  students(where: { class: { id: { equals: $id } } }, last: 1) {
    rollNo
  }
}
`;

export const GET_NOTES_SUBJECTS = `
query($Id:String){
  subjects(where:{group:{id:{equals:$Id}}}orderBy:{
    name:asc
  }){
    id
    name
    group{
      id
      name
    }
  }
}
`;

export const GET_NOTES_SUBJECTS_FOR_USER = `
query($Id:String $userId:String){
  subjects(where:{group:{class:{id:{equals:$Id}}}OR:[
    {students:{some:{id:{equals:$userId}}}}
    {teachers:{some:{id:{equals:$userId}}}}
  ]}orderBy:{
    name:asc
  } ){
    id
    name
    group{
      id
      name
    }
  }
}
`;

export const GET_NOTES_SUBJECTS_CHAPTERS = `
query($subjectId:String){
  chapters(where:{subject:{id:{equals:$subjectId}}}orderBy:{
    title:asc
  }){
    id
    title
  }
  
}
`;

export const GET_NOTES_SUBJECTS_CHAPTERS_USER = `
query($subjectId:String $userId:String){
  chapters(where:{subject:{id:{equals:$subjectId}}OR:[
    {subject:{students:{some:{id:{equals:$userId}}}}}
    {subject:{teachers:{some:{id:{equals:$userId}}}}}
  ]}orderBy:{
    title:asc
  }){
    id
    title
  }
  
}
`;
export const GET_CHAPTER_NOTES = `
query($chapterId:String){
  notes(where:{chapter:{id:{equals:$chapterId}}}orderBy:{
    title:asc
  }){
    id
    title
    url
  }
  
}
`;

export const GET_CHAPTER_NOTES_USER = `
query($chapterId:String $userId:String){
  notes(where:{chapter:{id:{equals:$chapterId}}OR:[
    {chapter:{subject:{students:{some:{id:{equals:$userId}}}}}}
    {chapter:{subject:{teachers:{some:{id:{equals:$userId}}}}}}
  ]}orderBy:{
    title:asc
  }){
    id
    title
    url
  }
  
}
`;

export const CREATE_STUDENT_FEES = `
mutation($description:String! $amount:Int!$studentId:String){
  createOneFee(data:{
    description:$description
    amount:$amount
    student:{connect:{id:$studentId}}
    
  }){
    id
  }
}
`;

export const UPDATE_STUDENT_FEES = `
mutation($description:String! $amount:Int ,$id:String){
  updateOneFee(data:{
    description:{set:$description}
    amount:{set:$amount}
  }where:{id:$id}){
    id
  }
}
`;
export const DELETE_STUDENT_FEES = `
mutation(,$id:String){
  deleteOneFee(where:{id:$id}){
    id
  }
}
`;
export const GET_STUDENT_FEES = `
query($id:String){
  student(where:{id:$id}){
    id
    Fees{
      id
      amount
      description
      createdAt
    }
    FeesReceived{
      id
      amount
      reference
      mode
      dateReceived
      createdAt
      comments
    }
  }
}
`;

export const CREATE_STUDENT_FEES_RECEIVED = `
mutation(
  $referenceId: String!
  $amount: Int!
  $studentId: String
  $mode: String!
  $comments: String!
  $receivedDate:DateTime!
) {
  createOneFeeReceived(
    data: {
      reference: $referenceId
      amount: $amount
      comments: $comments
      dateReceived: $receivedDate
      mode: $mode
      student: { connect: { id: $studentId } }
    }
  ) {
    id
  }
}
`;
export const UPDATE_STUDENT_FEES_RECEIVED = `
mutation(
  $referenceId: String!
  $amount: Int!
  $mode: String!
  $comments: String!
  $receivedDate: DateTime!
  $id: String
) {
  updateOneFeeReceived(
    where: { id: $id }
    data: {
      reference: {set:$referenceId}
      amount: {set:$amount}
      comments: {set:$comments}
      dateReceived: {set:$receivedDate}
      mode: {set:$mode}
    }
  ) {
    id
  }
}
`;

export const DELETE_STUDENT_FEES_RECEIVED = `
mutation($id:String){
  deleteOneFeeReceived(where:{id:$id}){
    id
  }
}
`;
export const GET_STUDENTS_W_BIRTHDAY = `
query($classId:String, $day:String , $batchId:String){
  students(where:{
    class:{
      id:{equals:$classId}
    }
    batch:{
      id:{equals:$batchId}
    }
    dateOfBirth:{endsWith:$day} 
  }){
    id
    name
   dateOfBirth
    batch{
      name
      group{
        name
      }
    }
  }
  }

`;

export const GET_SUBJECTS_PAPERS = `
query($subjectId:String){
  papers(where:{subject:{id:{equals:$subjectId}}}orderBy:{
    name:asc
  }){
    id
    name
  }
  
}
`;

export const GET_SUBJECTS_PAPERS_USER = `
query($subjectId:String $userId:String){
  papers(where:{subject:{id:{equals:$subjectId}}OR:[
    {subject:{students:{some:{id:{equals:$userId}}}}}
    {subject:{teachers:{some:{id:{equals:$userId}}}}}
  ]}orderBy:{
    name:asc
  }){
    id
    name
  }
  
}
`;

export const UPDATE_PAPER = `
mutation($name:String! $paperId:String){
  updateOnePaper(data:{
    name:{set:$name}
  }where:{id:$paperId}){
    id
  }
}
`;

export const ADD_SUBJECT_PAPER = `
mutation($name:String! $subjectId:String $groupId:String ,$classId:String){
  createOnePaper(data:{
    name:$name
    subject:{connect:{id:$subjectId}}
    group:{connect:{id:$groupId}}
    class:{connect:{id:$classId}}
  }){
    id
  }
}
`;

export const DELETE_MANY_QUESTIONS = `
mutation($paperId:String){
  deleteManyQuestion(where:{paper:{id:{equals:$paperId}}}){
    count
  }
}
`;
export const DELETE_PAPER = `
mutation($paperId:String){
  deleteOnePaper(where:{id:$paperId}){
    id
  }
}
`;

export const GET_PAPER_QUESTIONS = `
query($paperId:String){
  questions(where:{paper:{id:{equals:$paperId}}}
  orderBy:{
    order:asc
  }){
    id
    data
    answerData
    marks
    questionType
    title
    weightage
    order
  }
}
`;

export const GET_PAPER_QUESTIONS_USER = `
query($paperId:String $userId:String){
  questions(where:{paper:{id:{equals:$paperId}}OR:[
    {paper:{subject:{students:{some:{id:{equals:$userId}}}}}}
    {paper:{subject:{teachers:{some:{id:{equals:$userId}}}}}}
  ]}){
    id
    data
    answerData
    marks
    questionType
    title
    weightage
    order
  }
  
}
`;

export const CREATE_PAPER_QUSETION = `
mutation(
  $title: String!
  $paperId: String
  $weightage: String
  $marks: String
  $data: String!
  $answerData: String!
  $type: String!
  $order: Int!
) {
  createOneQuestion(
    data: {
      title: $title
      marks: $marks
      weightage: $weightage
      questionType: $type
      data: $data
      answerData: $answerData
      order: $order
      paper: { connect: { id: $paperId } }
    }
  ) {
    id
  }
}
`;

export const UPDATE_PAPER_QUESTION = `
mutation($id:String $title:String! $marks:String
  $data:String! $answerData:String! $type:String! $order: Int!){
    updateOneQuestion(data:{
      title:{set:$title}
      marks:{set:$marks}
      questionType:{set:$type}
      data:{set:$data}
      order: {set:$order}
      answerData:{set:$answerData}
      
    }where:{id:$id}){
      id
    }
  }
`;

export const DELETE_QUESTION = `
mutation($id:String){
  deleteOneQuestion(where:{id:$id}){
    id
  }
}
`;

export const GET_GROUP_BATCHES = `
query($id:String){
  batches(where:{group:{id:{equals:$id}}}){
    id
    name
  }
}
`;

export const CREATE_TEST = `
mutation(
  $name: String
  $available: Boolean!
  $batches: [BatchWhereUniqueInput!]
  $duration:String!
  $paperId:String
  $time:DateTime
  $type:String!
  $classId:String!
  $complete:Boolean
  
) {
  createOneTest(
    data: {
      name: $name
      available: $available
      batches: { connect: $batches }
      duration: $duration
      paper: { connect: { id: $paperId } }
      startTime: $time
      type: $type
      isComplete:$complete
      class: { connect: { id: $classId } }
    }
  ) {
    id
  }
}`;

export const UPDATE_TEST = `
mutation(
  $name: String
  $available: Boolean!
  $batches: [BatchWhereUniqueInput!]
  $duration:String!
  $paperId:String
  $time:DateTime
  $type:String!
  $classId:String!
  $complete:Boolean
  $id:String
  
) {
  updateOneTest(
    data: {
      name: {set:$name}
      available: {set:$available}
      batches: { connect: $batches }
      duration: {set:$duration}
      paper: { connect: { id: $paperId } }
      startTime: {set:$time}
      type: {set:$type}
      isComplete:{set:$complete}
      class: { connect: { id: $classId } }
    }where:{id:$id}
  ) {
    id
  }
}
`;
export const GET_NOT_COMPLETED_TESTS = `
query($classId:String){
  tests(where:{class:{id:{equals:$classId}}AND:{
    isComplete:{equals:false}
  }}){
    id
    name
    type
    batches{
      id
      name
      group{
        id
        name
      }
    }
    
    paper{
      id
      name
      subject{
        id
        name
      }
    }
    startTime
    duration
    available
    isComplete
  }
}
`;

export const GET_NOT_COMPLETED_TEST_USERS = `
query($classId:String $userId:String){
  tests(where:{class:{id:{equals:$classId}}AND:{
    isComplete:{equals:false}
  }OR:{
    paper:{subject:{teachers:{some:{id:{equals:$userId}}}}}
  }}){
    id
    name
    type
    batches{
      id
      name
      group{
        id
        name
      }
    }
    
    paper{
      id
      name
      subject{
        id
        name
      }
    }
    startTime
    duration
    available
    isComplete
  }
}
`;

export const GET_COMPLETED_TESTS = `
query($classId:String){
  tests(where:{
    class:{id:{equals:$classId}
  }AND:{
    isComplete:{equals:true}
  }}){
    id
    name
    type
    batches{
      id
      name
      group{
        id
        name
      }
    }
    
    paper{
      id
      name
      subject{
        id
        name
      }
    }
    startTime
    duration
    available
    isComplete
  }
}
`;

export const GET_COMPLETED_TEST_USER = `
query($classId:String $userId:String){
  tests(where:{
    class:{ id:{equals:$classId}
  }AND:{
    isComplete:{equals:true}
  }OR:{
    paper:{subject:{teachers:{some:{id:{equals:$userId}}}}}
  }}){
    id
    name
    type
    batches{
      id
      name
      group{
        id
        name
      }
    }
    
    paper{
      id
      name
      subject{
        id
        name
      }
    }
    startTime
    duration
    available
    isComplete
  }
}`;
export const DELETE_TEST = `
mutation($id:String){
  deleteOneTest(where:{id:$id}){
    id
  }
}
`;

export const UPDATE_COMPLETE = `
mutation($id:String, $completed:Boolean, $available:Boolean){
  updateOneTest(data:{
    isComplete:{set:$completed}
    available:{set:$available}
  }where:{id:$id}){
    id
  
}
}
`;
export const GET_ALL_CONTACT_US = `
query($classId:String){
  contactuses(where:{class:{id:{equals:$classId}}}){
    id
    name
    phone
    email
    message
  }
}
`;

export const GET_ALL_PERSONAL_COACHING = `
query($classId:String){
  personalCoaches(where:{class:{id:{equals:$classId}}}){
    id
    name
    phone
    email
    preferredDays
    areaIntrest
    college
    stream
    course   
  }
}
`;

export const GET_STUDENT_TESTS = `
query($subjectId:String!, $type: StringFilter){
  tests(where:{
    type:$type
    AND:{ 
    available:{equals:true}
    paper:{
      subject:{id:{equals:$subjectId}}
    }
    }
  }){
    id
    name
    available
    duration
    startTime
    type
  }
}
`;

export const GET_TEST_FOR_STUDENT = `
query($testId:String!){
  test(where:{
    id:$testId
  }){
    id
    name
    duration
    paper{
      questions{
        id
        data
        order
        answerData
        marks
        questionType
        title
        weightage
      }
    }
  }
}
`;

export const CREATE_STUDENT_TEST_RESPONSE = `
mutation($testId:String!, $studentId:String, $answers:String!){
  updateOneTest(where:{
    id:$testId
  }data:{
    response:{create:{
      marks:""
      marksOutOf:""
      submitted:true
      student:{connect:{id:$studentId}}
    	answers:$answers
    }}
  }){
    id
  }
}
`;

export const GET_TESTS_STUDENTS = `
query($testId:String){
  responses(where:{test:{id:{equals:$testId}}}){
    id
    checked
    student{
      id
      name
      rollNo
      batch{
        id
        name
      }
    }
  }
}
`;

export const CREATE_TEST_IMAGE = `
mutation($name:String!, $url: String!, $fileName:String!, $type:String!, $altText: String!, $classId:String!){
  createOneCloudDoc(data:{
   name:$name
   fileName:$fileName
   url:$url
   type:$type
   altText:$altText
   Class:{
     connect:{id:$classId}
   }
 }){
   id
   url
 }
 }
`;
export const ADD_CONTACT_US = `
mutation(
  $name: String!
  $email: String!
  $message: String!
  $phone: String!
  $classId: String
) {
  createOneContactUs(
    data: {
      name: $name
      email: $email
      message: $message
      phone: $phone
      class: { connect: { id: $classId } }
    }
  ) {
    id
  }
}
`;
export const DELETE_CONTACT_US = `
mutation($id:String){
  deleteOneContactUs(where:{id:$id}){
    id
  }
}
`;

export const UPDATE_CONTACT_US = `
mutation(
  $name: String!
  $email: String!
  $message: String!
  $phone: String!
  $classId: String
  $contactId:String
) {
  updateOneContactUs(where:{id:$contactId}
    data: {
      name: {set:$name}
      email: {set:$email}
      message: {set:$message}
      phone: {set:$phone}
      class: { connect: { id: $classId } }
    }
  ){
    id
  }
}

`;
export const CREATE_ONE_PERSONAL_COACHING = `
mutation($name: String!, 
  $phone: String!, 
  $email: String!, 
  $areaIntrest: String!
  $college:String!
  $days:String!
  $course:String!
  $stream:String!
  $classId:String
) {
  createOnePersonalCoach(
    data: {
      name: $name
      phone: $phone
      email: $email
      areaIntrest: $areaIntrest
      college: $college
      preferredDays: $days
      course:$course
      stream:$stream
      class: { connect: { id: $classId } }
    }
  ) {
    id
  }
}
  
`;

export const UPDATE_ONE_PERSONAL_COACHING = `
mutation($name: String!, 
  $phone: String!, 
  $email: String!, 
  $areaIntrest: String!
  $college:String!
  $days:String!
  $course:String!
  $stream:String!
  $classId:String
  $personalCoachId:String
) {
  updateOnePersonalCoach(where:{id:$personalCoachId}
    data: {
      name: {set:$name}
      phone: {set:$phone}
      email: {set:$email}
      areaIntrest: {set:$areaIntrest}
      college: {set:$college}
      preferredDays: {set:$days}
      course:{set:$course}
      stream:{set:$stream}
      class: { connect: { id: $classId } }
    }
  ) {
    id
  }
}
`;

export const DELETE_PERSONAL_COACH = `
mutation($id:String){
  deleteOnePersonalCoach(where:{id:$id}){
    id
  }
}
`;

export const GET_SUBJECTS_FOR_USER_IN_TEST = `
query($Id:String $ userId:String){
  subjects(where:{group:{id:{equals:$Id}}OR:{
    teachers:{some:{id:{equals:$userId}}}
  }}orderBy:{
    name:asc
  }){
    id
    name
    group{
      id
      name
    }
  }
}
`;

export const UPDATE_USER_EMAIL = `
mutation($name:String, $email: String!, $oldEmail: String!, $studentId: String,  $isNewStudent: Boolean ){
  upsertOneUser(
    where:{
      email:$oldEmail
    }
    update:{
      isVerified:{set:$isNewStudent}
      email:{set:$email}
      token:{set:$name}
      student:{connect:{id:$studentId}}
    }
    create:{
      isVerified:$isNewStudent
    	token:$name
      student:{connect:{id:$studentId}}
      email:$email
    }
  ){
   	id
  }
}
`;

export const CREATE_USER_FOR_STUDENT = `
mutation($studentId:String, $email:String!){
  createOneUser(data:{
    student:{connect:{id:$studentId}}
    email:$email
  }){
    id
  }
}
`;
