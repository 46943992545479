import React, { useContext, useState, useEffect } from "react"
import { Link, Redirect } from "react-router-dom"
import app from "../utils/base"
import { AuthContext } from "../modules/AuthContext"
import apiCall from "../Api"
import ButtonLoader from "../components/ButtonLoader"

const GET_USER_INFO = `
query($email:String){
  user(where:{
    email:$email
  }){
    id
		email
  }
}
`

const Signin = () => {
  const [redirect, setRedirect] = useState(false)
  const { user } = useContext(AuthContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) {
      setRedirect(true)
    }
  }, [user])

  const signInUser = () => {
    // Client side validation

    if (!email) {
      alert("Please enter Email to continue.")
      return
    }

    setLoading(true)
    // Check if email exists in our system for this class

    apiCall(GET_USER_INFO, { email }).then((res) => {
      // if above is ok, then allow user to login
      if (res.data.user) {
        console.log("Found Email Yaay")

        app
          .auth()
          .signInWithEmailAndPassword(email, password)
          .finally(function (data) {
            setLoading(false)
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            alert(errorMessage)
          })
      } else {
        console.log("Not Found Email Boooooooo")
        alert(
          "Your Email is not registered in our system, Please contact the Admin."
        )
        setLoading(false)
        return
      }
    })
  }

  if (redirect) {
    switch (user.type) {
      case "admin":
        return <Redirect to="/adminDash/dash" />
      case "teacher":
        return <Redirect to="/teachersDash/dash" />
      case "student":
        return <Redirect to="/studentsDash/dash" />

      default:
        break
    }
    //logout the user
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto  w-auto"
          src={require("../logo-alpha.png")}
          alt="Alpha-Arts"
        />
        {/* <h1 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Alpha Arts Academy
        </h1> */}
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Email address
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                type="email"
                value={email}
                // required
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="mt-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-5 text-gray-700"
            >
              Password
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="password"
                type="password"
                value={password}
                // required
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="mt-6 flex items-center justify-between">
            <Link to="/forgot">
              <div className="text-sm leading-5">
                <button
                  href="/"
                  className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </button>
              </div>
            </Link>
          </div>

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                onClick={signInUser}
                disabled={loading}
              >
                <ButtonLoader size={20} loading={loading} />
                Sign in
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signin
