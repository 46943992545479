import React, { Suspense } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Signin from "./screens/Signin"
import ForgotPassword from "./screens/ForgotPassword"
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil"
import { ReactQueryDevtools } from "react-query-devtools"
import AuthProvider from "./modules/AuthContext"
import PrivateRoute from "./modules/PrivateRoute"
const AdminDash = React.lazy(() => import("./screens/AdminDash"))
const TeachersDash = React.lazy(() => import("./screens/TeachersDash"))
const StudentDash = React.lazy(() => import("./screens/StudentDash"))

// import './App.css';

export const classId = "ckcyo673f02753kpf61rt07e3"

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute allowed="admin" path="/adminDash">
            <Suspense
                fallback={
                  <div>
                    <p>Loading...</p>
                  </div>
                }
              >
              <AdminDash />
              </Suspense>
            </PrivateRoute>
            <PrivateRoute allowed="teacher" path="/teachersDash">
            <Suspense
                fallback={
                  <div>
                    <p>Loading...</p>
                  </div>
                }
              >
              <TeachersDash />
              </Suspense>
            </PrivateRoute>
            <PrivateRoute allowed="student" path="/studentsDash">
            <Suspense
                fallback={
                  <div>
                    <p>Loading...</p>
                  </div>
                }
              >
              <StudentDash />
              </Suspense>
            </PrivateRoute>
            <Route path="/forgot">
              <ForgotPassword />
            </Route>
            <Route path="/">
              <Signin />
            </Route>
          </Switch>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthProvider>
    </RecoilRoot>
  )
}

export default App
